@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

* {

}
:root {
  --color-primary: #6f6af8;
  --color-white: #fff;
  --color-red: rgb(255, 63, 63);
  --color-gray-100: hsl(220, 20%, 10%, 5%);
  --color-gray-200: hsl(220, 20%, 10%, 5%);
  --color-gray-300: hsl(220, 20%, 10%, 5%);
  --color-gray-500: hsl(220, 20%, 10%, 5%);
  --color-gray-700: #252543;
  --color-gray-900: #0c0c24;

  --color-bg: hwb(240, 95%, 0%);
  --transition: all 300 ms ease;

  --container-width-lg: 85%;
  --container-width-md: 94%;

  --form-width: 55%;

  --radius-1: 0.3rem;
  --radius-2: 0.6rem;
  --radius-3: 1rem;
  --radius-4: 2rem;
}

/* General Styles */
.header {
 
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Time Container */
.time-container {
  display: flex;
  gap: 20px;
}

.date-time {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Social Media Icons */
.social-media {
  display: flex;
  gap: 10px;
}

.social-icon {
  font-size: 20px;
  color: #333;
}

/* Login Link Styles */
.login-link {
  /* display: flex; */
  list-style: none;
  
}

.login-items {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.login-items li {
  display: inline;
}

.login-items li a {
  text-decoration: none;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Hide header on small screens */
  .time-container,.social-media {
    display: none;
  }
  .login-link {
    display: block;
    text-align: center;
  
  } 

  .login-link li{
    font-size: 10px;
  }

  .login-items {
    flex-direction:column;
    gap: 10px;
    text-align: start;
  }
}

@media (min-width: 769px) {
  /* Show header content on larger screens */
.time-container,.social-media{
    display: flex;
  }
  .login-link {
    display: flex;
    justify-content: flex-end;
  }

  .login-items {
    flex-direction: row;
    gap: 15px;
  }
}

/* Hidden Sections after Login */
.hidden-after-login {
  display: none;
}

/* Custom Classes for Dynamic Conditions */
.logged-in .time-container,
.logged-in .social-media {
  display: none;
}






/* navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.menu-toggle {
  display: none; /* Hidden on larger screens */
}

.navbar {
  background-color:white;
  padding: 15px 20px;
  position: sticky;
  align-items: center;
  color: #142247;
  justify-content: center;
  z-index: 1000;
overflow: hidden;
  top: 0;
}

.nav-container {
  /* display: flex; */
  justify-content: space-between;

}


.nav-container button{
  outline:none;
}
/* Center the Logo */
.nav-logo {
  flex: 1; /* Allow the logo to take space */
  display: flex;
  background:#142247;
  justify-content: center; /* Center the logo horizontally */
}

.logo {
  max-width: 290px;
}

/* Mobile Toggle Button */
.menu-toggle {
  display: none;
  background:#142247;
  color: white;
  font-size: 10px;
  cursor: pointer;
}

/* Nav Links */
.nav-links {
  list-style: none;
  display: flex;
  color: #142247;
  font-weight: bold;
  /* align-items: center;
  justify-items: center; */
}

.nav-links li {
  margin-left: 30px;
}

.nav-links a {
  color: #142247;;
  text-decoration: none;
  width: 100%;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-links a:hover {
  box-shadow: #007bff;
}

/* Mobile View */
@media (max-width: 800px) {
  .logo {
    max-width: 250px;
  
  }
  
  .navbar{
    justify-content:left;
    background:#142247;
    padding: 10px 10px;
      color: #142247;
 
  }
 
  .menu-toggle {
    display: block; 
    outline:none;
    /* Show toggle button on mobile */
   
  }

  .nav-links {
    /* position: absolute; */
    top: 60px;
    left: 0;
    /* width: 100%; */
    background-color: #142247;
    flex-direction: column;
    align-items: start;
    color: white;
    display: none;
    transition: all 0.3s ease;
  }
  .nav-links a{
    color: white;
  }

  .nav-links.open {
    display: flex; /* Show nav links when open */
  }

  .nav-links li {
    margin: 15px 0; /* Space out links */
  }
}

a {
  color: var(--color-gray-700);
  text-decoration: none;
}
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-gray-900);
  line-height: 1.1;
}

h1 {
  font-size: 2.2rem;
overflow: hidden;
}
h2 {
  font-size: 1.9rem;
}
h3 {
  font-size: 1.3rem;
  overflow: hidden;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  font-size: 1.4rem;
}

img {
  display: block;
  object-fit: cover;
  width: 250%;
}

section {
  margin-top: 2rem;
}

.container {
  width: var(--container-width-lg);
  margin-inline: auto;
}

.container h2 {
  /* width: var(--container-width-lg);
  margin-inline: auto; */
  padding-bottom: 40px;
}

.btn {
  display: inline-block;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: var(--radius-2);
  background: var(--color-white);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.btn.primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.btn.category {
  background-color: var(--color-bg);
  color: var(--color-primary);
  font-size: 0.75rem;
  font-weight: 400;
}

.btn.sm {
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
  font-weight: 400;
}

.btn.danger {
  background-color: var(--color-red);
  color: var(--color-white);
}
.btn:hover {
  background: var(--color-gray-900);
  color: var(--color-white);
}

.center {
  text-align: center;
  padding-top: 5rem;
  margin-inline: auto;
  display: block;
  width: 100%;
}

.error-page h2 {
  margin-top: 3rem;
}



/*=================================Footer=================== */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}

.footer-container {
  background-color: #142247;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo-address {
  width: 30%;
  line-height: 1.6;
}
.footer-logo-address a{
  color: white;
}

.footer-logo {
  max-width: 400px;
  margin-bottom: 10px;
}

.footer-links {
  width: 65%;
}

.footer-section {
  margin-bottom: 15px;
}

.footer-section strong {
  color: #ffcc00; /* Yellow color for headings */
  font-size: 18px;
  margin-bottom: 5px;
}

.footer-section p {
  margin: 0;
  line-height: 1.8;
}

.footer-section a {
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}


@media screen and (max-width: 800px) {
  .footer-container {
    display: flex;
    flex-direction: column;
  }
  .footer-logo-address {
    width: 100%;
    line-height: 1.6;
  }
  .footer-logo{
    max-width: 170px;
  }
  .footer-section p {
    margin: 0;
    line-height: 1.8;
    width: 100%;
  }
  section {
    margin-top: 0rem;
  }
}

/* sliders */
.events-slider {
  padding: 0px;
  background-color: #f9f9f9;
}

.event-card {
  padding: 0px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.event-card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.event-card p {
  font-size: 1rem;
  color: #666;
}

/*=================================Home=================== */
.home {
  margin-bottom: 4rem;
}
/*=================================Posts=================== */

.posts__container {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem;
}
.events-section {
  padding: 20px;
}

.events__container {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap to the next line */
  justify-content: space-between; /* Adds space between items */
}

.event-card {
  flex: 1 1 calc(33.333% - 20px); /* Three items per row with some margin */
  margin: 10px;
  background-color: #fff; /* Optional background color for event cards */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow effect */
  overflow: hidden; /* Ensures no overflow from images */
  transition: transform 0.2s; /* Smooth scale effect on hover */
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-card:hover {
  transform: scale(1.05); /* Scale effect on hover */
}

.event__thumbnail {
  overflow: hidden; /* Hide overflow from images */
  flex-shrink: 0;
}

.event_image {
  width: 100%; /* Make the image responsive */
  height: 300px; 
  object-fit: cover; /* Ensure image covers the space without stretching */
}

.event__content {
  padding: 5px; /* Padding around the content */
  flex-direction: column;
  justify-content: space-between;
}

.event__content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  max-height: 40px; /* Restrict title height */
  overflow: hidden; /* Hide overflow if the title is too long */
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event__content p {
  font-size: 0.7em;

  /* line-height: 1.4; */
 
  overflow: hidden; /* Hide overflow if content is too long */
  text-overflow: ellipsis;
}

.pagination-container {
  display: flex; /* Use flexbox for pagination buttons */
  justify-content: center; /* Center the pagination buttons */
  margin-top: 20px; /* Space above the pagination */
}

.pagination-button {
  padding: 10px 15px; /* Padding for buttons */
  margin: 0 5px; /* Margin between buttons */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3; /* Darker color on hover */
}

.pagination-button:disabled {
  background-color: #cccccc; /* Gray out disabled buttons */
  cursor: not-allowed; /* Show not-allowed cursor */
}

@media (max-width: 768px) {
  .event-card {
    flex: 1 1 calc(50% - 20px); /* Two items per row on medium screens */
  }
}

@media (max-width: 480px) {
  .event-card {
    flex: 1 1 100%; /* One item per row on small screens */
  }
}


.post {
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--radius-4);
  padding-bottom: rem;
  transition: var(--transition);
  cursor: default;
}
.post:hover {
  box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, 0.1);
}

.post__thumbnail {
  border-radius: var(--radius-3);
  overflow: hidden;
  height: 20rem;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
}
.post__thumbnail img {
  /* width: auto; */
  height: 100%;
}

.post__content {
  margin-top: 1.5rem;
  overflow: hidden;
}

.post__content h3 {
  margin: 1rem 0 1rem;
  overflow: hidden;
}
.post__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
}
.post__author {
  /* display: flex; */
  /* gap: 1rem; */
  align-items: flex-start;
}


/*=================================Posts Detail=================== */
.post-detail {
  /* width: var(--form-width); */
  background: var(--color-white);
  margin-inline: auto;
  padding: 2rem 0;
}

.post-detail__header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.post-detail__buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.post-detail__thumbnail {
  margin: 1rem auto;
  height: 100%;
  overflow: hidden;
  text-align: center; /* Add this to center the image */
  display: flex;
}

/* .post-detail__thumbnail img { */
/* max-width: 100%; Add this to scale the image to fit the container */
/* height: auto; */
/* margin: 0 auto; Add this to center the image horizontally */
/* display: block; Add this to remove any inline spacing */
/* } */

.post-detail p {
  /* margin-bottom: 1rem; */
  text-justify: auto;
  text-shadow: 1px 1px 2px rgb(226, 226, 236);
  width: 100%;
  padding: px;
  /* line-height: 1.9; */
  text-align: justify;
  word-wrap: break-word;
}

/*=================================Register and Form styles=================== */
/* General styling */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  background: linear-gradient(135deg, #f9f9f9, #f0f0f0);
}

.logincontainerr {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.login__box {
  padding: 20px;
}

.login__header {
  text-align: center;
  margin-bottom: 20px;
}

.newspaper-icon {
  max-width: 15rem;
  margin-top: 1px;
}

h2 {
  font-size: 24px;
  color: #333;
}

.form__error-message {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s ease-in-out;
}

.input-field:focus {
  border-color: #3498db;
  outline: none;
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: #2980b9;
}

.account-link {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.account-link a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.account-link a:hover {
  text-decoration: underline;
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .container {
    max-width: 95%;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 20px;
  }

  .input-field {
    font-size: 14px;
    padding: 8px;
  }

  .btn {
    font-size: 16px;
  }
}

/* .form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

input,
select,
textarea {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: var(--radius-4);
  resize: none;
  font-size: 1.2rem;
  border: 0.1px solid black;
  
}

.form__error-message {
  background-color: red;
  border: 0.1px solid white;
  color: white;
  font-size: 1.3rem;
  padding: 0.4rem;
  border-radius: var(--radius-2);
  display: flex;
  margin-bottom: 1rem;
  font-style: italic;
  margin: 1rem;
  justify-content: center;
}

.register,
.login,
.-create-post {
  width: var(--form-width);
  margin-inline: auto;
  height: calc(100vh -12rem);
  display: grid;
  place-items: center;
  padding-top: 10rem;
  border-radius: var(--radius-2);
}
.register h2,
.login h2,
.-create-post h2 {
  margin-bottom: 2rem;
}

.register small,
.login small {
  display: flex;
  margin-top: 1.5rem;
}

.register small a,
.login small a {
  color: var(--color-primary);
} */

/* custom style for react quill  */

.ql-editor {
  overflow: scroll;
  background: var(--color-white);
  height: 10rem !important;
}

/* create post of admin */

/* General styling */
body {
  font-family: "Poppins", sans-serif;
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.create-post {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  background-color: #f9f9f9;
}

.createcontainer {
  width: 100%;
  max-width: 500px;
  padding: 30px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input[type="text"],
select,
.ql-container {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

input[type="text"]:focus,
select:focus,
.ql-container:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0px 3px 8px rgba(52, 152, 219, 0.3);
}

select {
  color: #333;
  font-size: 16px;
  background: #f1f1f1;
}

input[type="file"] {
  background-color: #3498db;
  color: white;
  padding: 12px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}

input[type="file"]:hover {
  background-color: #2980b9;
}

button.btn.primary {
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #3498db;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

button.btn.primary:hover {
  background-color: #2980b9;
}

.ql-toolbar {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.ql-container {
  height: 200px;
  border-radius: 0 0 8px 8px;
}

.form__error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  h2 {
    font-size: 1.5rem;
  }

  button.btn.primary {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.3rem;
  }

  button.btn.primary {
    font-size: 14px;
    padding: 12px;
  }

  input[type="text"],
  select,
  .ql-container {
    font-size: 14px;
  }
}

/*=================================Authors================== */

.authors__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  padding-top: 5rem;
}

.author {
  background: var(--color-gray-300);
  padding: 1rem;
  border-radius: var(--radius-3);
  display: flex;
  gap: 1rem;
  transition: var(--transition);
  place-items: center;
}

.author:hover {
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.07);
}

.author__avatar {
  width: 6rem;
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: 0.3rem solid var(--color-bg);
  overflow: hidden;
  display: flex;
  padding: 0rem;
}

.author__info p {
  margin-top: 1rem;
}

/*=================================UserProfile ================== */
/* Profile Section */
.profile {
  background: #f4f7fc;
  padding: 40px 0;
}

.profile__container {
  max-width: 40rem;
  margin: 0 auto;
  background: white;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.profile__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile__avatar {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #4a90e2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar__form {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.avatar__form input[type="file"] {
  display: none;
}

.avatar__form label {
  background-color: #4a90e2;
  color: white;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.avatar__form label:hover {
  background-color: #357ab8;
}

.profile__avatar-btn {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.profile__avatar-btn:hover {
  background-color: #218838;
}

/* Typography */
h1 {
  font-size: 28px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.form__error-message {
  color: red;
  font-size: 14px;
}

/* Form Styling */
.form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form input:focus {
  outline: none;
  border-color: #4a90e2;
}

.mypost {
  background-color: #6523e0;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn.primary:hover {
  background-color: #357ab8;
}

/* "My Posts" Button */
.mypost {
  background-color: #0a2fe7;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #3a40ff;
}

.profile__form button[type="submit"] {
  background-color: #28a745;
}

.profile__form button[type="submit"]:hover {
  background-color: #218838;
}

/* .profile__container {
  display: grid;
  place-items: center;
  text-align: center;
}

.profile__details {
  width: 100%;
}

.avatar__wrapper {
  width: 15rem;
  aspect-ratio: 1/1;
  position: relative;
  margin-inline: auto;
  margin-top: 3rem;
}
.profile__avatar {
  height: 100%;
  border-radius: 50%;
  border: 0.2rem solid black;
  overflow: hidden;
  display: flex;
  border-style: dotted;
}
.avatar__form {
  height: 1rem;
}
.avatar__form input {
  visibility: hidden;
}

.avatar__form label,
.profile__avatar-btn {
  background: var(--color-gray-900);
  color: var(--color-white);
  font-size: 1.3rem;
  position: absolute;
  right: 2rem;
  bottom: 1.4rem;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.profile__avatar-btn {
  cursor: pointer;
  padding: 0;
  background: var(--color-primary);
}

.profile__details h1 {
  margin-top: 1.5rem;
}

.form.profile__form {
  margin-top: 3rem;
  margin-inline: auto;
  width: var(--form-width);
} */

/*=================================Dashboard================== */

.dashboard__container {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}

.dashboard__post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  background: var(--color-white);
  padding: 2rem 0.3rem;
  border-radius: var(--radius-4);
}

.dashboard__post-info {
  display: flex;
  gap: 3rem;
  width: 100%;
  align-items: center;
}

.dashboard__post-thumbnail {
  height: 100px;
  width: 100px;
  border-radius: var(--radius-4);
  border: 0.1rem solid black;
  overflow: hidden;
  display: flex;
}

.dashboard__post-actions {
  display: flex;
  gap: 1rem;
}

/*===========================================LOADER=================================================*/

/* From Uiverse.io by SchawnnahJ */
.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader:before {
  animation: before8 2s infinite;
}

.loader:after {
  animation: after6 2s infinite;
}

@keyframes before8 {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
      0 0.5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
      1em 0.5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }
}

@keyframes after6 {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
      -0.5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
      -0.5em 1em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}

/*=================================Media queries================== */

@media screen and (max-width: 800px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  .container {
    width: var(--container-width-lg);
  }

 

  .posts__container {
    grid-template-columns: 1fr;
    gap: rem;
  }

  .footer__categories {
    flex-direction: column;
    justify-content: center;
  }

  .post-detail {
    width: 90%;
    background: transparent;
    padding: 0;
  }

  .post-detail h1 {
    margin-top: rem;
    /* color: red; */
    font-size: medium;
  }

  .register,
  .login,
  .-create-post {
    /* width: 80%; */
  }

  .form.profile__form {
    margin-top: 2.5rem;
    width: 100%;
  }

  .authors__container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }
  .dashboard__post {
    flex-direction: column;
  }

  .dashboard__post-info {
    gap: 1.5rem;
  }

  .dashboard__post-thumbnail {
    width: 5rem;
  }
}

/* =======================================================admin ================================================== */

/* Admin panel styles */
.admin-layout {
  display: flex;
}

.admin-layout aside {
  width: 200px;
  background: #f4f4f4;
}

.admin-layout aside nav ul {
  list-style: none;
  padding: 0;
}

.admin-layout aside nav ul li {
  padding: 10px;
}

.admin-layout aside nav ul li a {
  text-decoration: none;
  color: #333;
}

.admin-layout main {
  flex: 1;
  padding: 20px;
}

/* =====================================Admin Dashboard========================================== */
/* AdminDashboard.css */

/* AdminDashboard.css */

.admin-dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.admin-section {
  margin-bottom: 40px;
}

.admin-section h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #555;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  text-align: center;
}

.card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.card p {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #666;
}

.card-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

.card-link:hover {
  background-color: #0056b3;
}

.role {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
}

.admin-role {
  background-color: #28a745;
  color: #fff;
}

.user-role {
  background-color: #ffc107;
  color: #333;
}

.loading,
.error {
  text-align: center;
  font-size: 1.5rem;
  color: #777;
  margin-top: 20px;
}

/* =====================================Admin UserDetails========================================== */

/* UserDetail.css */

.user-detail {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.user-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.user-card-content {
  text-align: left;
}

.user-card p {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #666;
}

.role {
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
}

.admin-role {
  background-color: #28a745;
  color: #fff;
}

.user-role {
  background-color: #ffc107;
  color: #333;
}

.loading,
.error {
  text-align: center;
  font-size: 1.5rem;
  color: #777;
  margin-top: 20px;
}

.back-button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

/* ======================================user avatar =============================== */

.user-avatar img {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  border-radius: 50%; /* Makes the avatar round */
  object-fit: cover; /* Ensures the image covers the entire area */
}

/* ===================================Review Section==================================== */
.review-form {
  margin-top: 40px;
}

.review-form label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
}

.review-form textarea,
.review-form select {
  width: 50%;
  border-radius: 0%;
  padding: 10px;
  margin-bottom: 10px;
}

.review-form button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.review-form button:disabled {
  background-color: #cccccc;
}

.reviews {
  margin-top: 30px;
}

.review {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.review strong {
  display: block;
  font-size: 1.1em;
}

.review p {
  margin: 5px 0;
}

/* =======================================tooltip like section============================== */

.like-section .btn {
  position: relative;
}

.like-section .btn::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.like-section .btn:hover::after {
  visibility: visible;
  opacity: 1;
}

/* ==========================================Recommendations======================================= */

/* Recommendations.css */

/* General container styles */
.recommendations-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.recommendations-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.no-recommendations {
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
}

/* Grid layout for posts */
.post {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.post:hover {
  transform: translateY(-5px);
}

.post-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.post-title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.post-description {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: justify;
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .recommendations-container {
    padding: 10px;
  }

  .post {
    margin: 10px 0;
  }

  .post-title {
    font-size: 18px;
  }

  .post-description {
    font-size: 14px;
  }
}

/* Responsive grid layout for larger screens */
@media (min-width: 768px) {
  .recommendations-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
}

/* ===================================Paginate=============================================== */
/* Pagination container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination button styles */
.pagination-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 10px;
  margin: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #0056b3;
}

/* Active page button */
.pagination-button.active {
  background-color: #0056b3;
  font-weight: bold;
}

/* Disabled button style */
.pagination-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #004c99; /* Button color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top:hover {
  background-color: #003366; /* Hover color */
}

.scroll-to-top:active {
  background-color: #002244; /* Active color */
}

/* Initially hide the button */
.scroll-to-top {
  opacity: 0;
  visibility: hidden;
}

/* Show button when user scrolls */
.scroll-to-top {
  opacity: 1;
  visibility: visible;
}

   
.advertisement-section {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically (if needed) */
  overflow-x: hidden;
  padding: 0px; /* Add padding for better spacing */
}

.advertisements__container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  justify-content: center; /* Center the ads within the container */
  align-items: center; /* Align ads to the center vertically */
 
  margin: 0 auto; /* Ensure the container is centered */
}

.ad_image {

  max-width: 1350px; /* Max width for larger screens */
  margin: 20px; /* Add margin for spacing between ads */
}

.advertisement-image {
  width: 100%; /* Make sure the image fills the container */
  height: auto; /* Automatically adjust the height based on the width */
  max-height: 200px; /* Set a maximum height */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 0px;
}

/* For medium-sized screens (like tablets) */
@media (max-width: 768px) {
  .advertisement-image {
    max-height: 150px; /* Adjust maximum height for tablets */
  }
}

/* For small screens (like mobile phones) */
@media (max-width: 480px) {
  .advertisement-section {
  
    padding: 10px; /* Add padding for better spacing */
  }
  .ad_image {
    max-width: 100%; 
    margin: 10px;/* Ensure full width for smaller screens */
  }
  .advertisement-image {
    max-height: 150px; /* Keep a fixed height for smaller screens */
  }
}

  
.delete:hover {
  background-color: darkred;
}
  

.date-converter {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.input-container {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-container button {
  margin-top: 10px;
  padding: 10px;
  /* width: 100%; */
  color:white;
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
  border-radius: 4px;
  background: #0056b3;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}



.footer-logo-address button {
  margin-top: 10px;
  padding: 10px;
  /* width: 100%; */
  color:white;
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  font-size: 15px;
}

.footer-logo-address button:hover {
  background-color: #dde4ed;
}

.result {
  margin-top: 20px;
  text-align: center;
}

h3 {
  color:#7F2738;

}



.footer-logo-address .datebutton {
  cursor: pointer;
  color: #0056b3;
  background: #142247;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  margin-left: 1rem;
}

.footer-logo-address button:hover {
  color: white;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
}

.footer-logo-address button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #142247;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.footer-logo-address .datebutton:hover::before {
  width: 250%;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .footer-logo-address .datebutton {
    font-size: 13px;
    margin-left: 0.5rem;
  }

  
  .footer-logo-address button:hover {
    transform: scale(1.05); /* Reduce the scale on hover for small screens */
  }

  .footer-logo-address button::before {
    left: -30px; /* Adjust the left position on smaller screens */
  }
}
.input-container button{
  font-size: 13px;
  margin-left: 0.5rem;
}

@media (max-width: 480px) {
  .footer-logo-address .datebutton {
    font-size: 12px;
    margin-left: 0.2rem;
    padding: 0.4em 1em;
    margin-bottom: 2rem; /* Adjust padding for small screens */
  }
  .input-container button{
    font-size: 12px;
    margin-left: 0.2rem;
    padding: 0.4em 1em;
  }
  
  .footer-logo-address button:hover {
    transform: scale(1.03); /* Even smaller hover effect for very small screens */
  }

  .footer-logo-address button::before {
    left: -20px;
  }
}
.ad_image a {
  pointer-events: auto; /* Make sure pointer-events are enabled */
}

.footcopy p{
  text-align: center;
}